<template>
  <div class="participant-form">
    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          class="w-full"
          v-model="participant.name"
          v-validate="'required'"
          name="participantName"
          :danger="errorParticipantName && errors.has('participantName')"
          :danger-text="$t('shops.errors.participant_name_is_required')"
        />
        <vs-input v-else v-model="participant.translations[language].name" class="w-full" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div  v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.description_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          class="w-full"
          v-model="participant.description"
          name="participantDescription"
          :danger="errorParticipantDescription && errors.has('participantDescription')"
          :danger-text="$t('shops.errors.participant_description_is_required')"
        />
        <vs-input v-else v-model="participant.translations[language].description" class="w-full" />
      </div>
    </div>

    <div class="vx-row mb-6 mt-10">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.external_id_info") }}</label>
        <vs-input
          class="w-full"
          v-model="participant.external_id"
          name="participantExternalID"
        />
      </div>
      <div class="mb-6 vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.catalog.0") }}</label>
        <v-select
          v-if="catalogList.length > 0"
          v-model="participant.catalogs"
          v-validate="'required'"
          :options="catalogList.map((catalog) => ({ label: catalog.name, value: catalog.id }))"
          @input="(catalog) => selectCatalog(catalog)"
          class="w-full select-large"
          :class="{'danger-border-select': errorCatalog && errors.has('catalog')}"
          :value="participant.catalog_id"
          name="catalog"
          :placeholder="$t('shops.select_a_catalog')"
          :danger="errorCatalog && errors.has('catalog')"
          :danger-text="$t('shops.errors.catalog_is_required')"
        />
      </div>
    </div>
    <div class="vx-col 1/3">
      <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
  name: "ParticipantForm",

  mixins: [notifications, shopLanguages],

  props: {
    value: { type: Object, default: null }
  },

  data() {
    return {
      catalogID: 0,
      errorParticipantName: false,
      errorCatalog: false,
      errorParticipantDescription: false,
      participant: {}
    };
  },

  computed: {
    ...mapGetters("shops/catalogs", { catalogList: "getAll" }),
  },

  methods: {
    ...mapActions("shops/catalogs", { loadCatalogs: "load" }),
    validateAndSubmit() {
      const payload = {...this.languages().reduce((carry, lang) => {
        carry[lang] = this.participant.translations[lang]
        return carry
      }, {}), ...this.participant}

      this.$validator.validateAll().then((result) => {
        if (result) {
          payload.catalogs = [ this.catalogID ];
          this.$emit("submit", payload);
        } else {
          this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.participant_validation_failed"));
          if (!this.participant.name) this.errorParticipantName = true;
          if (!this.participant.description) this.errorParticipantDescription = true;
          if (!this.catalogID) this.errorCatalog = true;
        }
      });
    },

    selectCatalog(option) {
      this.catalogID = option === null ? option : option.value;
    },
  },
  async created() {
    this.participant = JSON.parse(JSON.stringify(this.value))
    this.catalogID = this.participant.catalog_id
    await this.loadCatalogs()
  }
};
</script>

<style>
</style>
