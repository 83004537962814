<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
        <vs-button v-if="showForm" @click="onCreate()">{{ $t("shops.back") }}</vs-button>

        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.participants.0')"
            @update="loadUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <participant-form v-model="formData" @submit="submitParticipantForm" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<style></style>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import ParticipantForm from "@/modules/Shops/Pages/forms/ParticipantForm.vue";

export default {
  name: "Participants",
  mixins: [ notifications, table_functions, shopLanguages ],
  components: {
    ParticipantForm,
    DataTable,
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/participants", {
      participantList: "getAll",
      catalogParticipants: "getByCatalogId",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      participantById: "getById",
    }),

    ...mapGetters("shops/catalogs", {
      catalogs: "getAll",
    }),

    tableData() {
      return this.participantList.map(this.participantToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
      }
    },
  },

  methods: {
    ...mapActions("shops/participants", {
      loadData: "load",
      removeData: "remove",
      updateParticipant: "update",
      createParticipant: "create"
    }),
    ...mapActions("shops/catalogs", { catalogsLoad: "load" }),

    participantToTableData(participant) {
      if (!participant) {
        return {}
      }
      return {
        id: participant.id,
        name: participant.name,
        description: participant.description,
        catalog: participant.catalogs != undefined && participant.catalogs.length > 0 ? participant.catalogs[0].name : "-",
        created_at: participant.created_at,
        updated_at: participant.updated_at,
      };
    },

    async submitParticipantForm(participant) {
      this.$vs.loading();
      try {
        if (participant.id === 0) {
          await this.createParticipant(participant);
          this.notifySuccess(i18n.t("shops.save"), `${i18n.t('shops.participant')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`);
        } else {
          await this.updateParticipant(participant);
          this.notifySuccess(i18n.t("shops.updated"), `${i18n.t('shops.participant')} ${participant.name} ${i18n.t('shops.has_been_updated_successfully')}`);
        }
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.failed_to_save_participant"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async loadUpdate(participant) {
      const translationsDefault = this.translationsDefault(['name', 'description'])

      this.formData = Object.assign({}, this.formData, {
        ...await this.participantById(participant.id),
        translations: { ...translationsDefault, ...this.participantById(participant.id).translations }
      })

      this.formData.catalog_id = this.formData.catalogs[0].catalog_id
      this.formData.catalogs = participant.catalog

      this.$store.dispatch("shops/participants/assignTranslations",this.formData)

      this.showForm = true
    },

    default() {
      return {
        id: 0,
        catalogs: [],
        external_id: "",
        translations: this.translationsDefault(['name', 'description']),
        name: "",
        description: "",
        catalog_id: 0,
      }
    }
  },

  created() {
    this.formData = this.default()
    this.$store.commit("shops/participants/RESET_RESOURCE");
    this.load({page: 1, page_size: this.perPage})
  },
};
</script>

<style></style>
