var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "participant-form" }, [
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: {
                    name: "participantName",
                    danger:
                      _vm.errorParticipantName &&
                      _vm.errors.has("participantName"),
                    "danger-text": _vm.$t(
                      "shops.errors.participant_name_is_required"
                    )
                  },
                  model: {
                    value: _vm.participant.name,
                    callback: function($$v) {
                      _vm.$set(_vm.participant, "name", $$v)
                    },
                    expression: "participant.name"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  model: {
                    value: _vm.participant.translations[language].name,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.participant.translations[language],
                        "name",
                        $$v
                      )
                    },
                    expression: "participant.translations[language].name"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.description_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "participantDescription",
                    danger:
                      _vm.errorParticipantDescription &&
                      _vm.errors.has("participantDescription"),
                    "danger-text": _vm.$t(
                      "shops.errors.participant_description_is_required"
                    )
                  },
                  model: {
                    value: _vm.participant.description,
                    callback: function($$v) {
                      _vm.$set(_vm.participant, "description", $$v)
                    },
                    expression: "participant.description"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  model: {
                    value: _vm.participant.translations[language].description,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.participant.translations[language],
                        "description",
                        $$v
                      )
                    },
                    expression: "participant.translations[language].description"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "vx-row mb-6 mt-10" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.external_id_info")))
          ]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { name: "participantExternalID" },
            model: {
              value: _vm.participant.external_id,
              callback: function($$v) {
                _vm.$set(_vm.participant, "external_id", $$v)
              },
              expression: "participant.external_id"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-6 vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.catalog.0")))
          ]),
          _vm.catalogList.length > 0
            ? _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-full select-large",
                class: {
                  "danger-border-select":
                    _vm.errorCatalog && _vm.errors.has("catalog")
                },
                attrs: {
                  options: _vm.catalogList.map(function(catalog) {
                    return { label: catalog.name, value: catalog.id }
                  }),
                  value: _vm.participant.catalog_id,
                  name: "catalog",
                  placeholder: _vm.$t("shops.select_a_catalog"),
                  danger: _vm.errorCatalog && _vm.errors.has("catalog"),
                  "danger-text": _vm.$t("shops.errors.catalog_is_required")
                },
                on: {
                  input: function(catalog) {
                    return _vm.selectCatalog(catalog)
                  }
                },
                model: {
                  value: _vm.participant.catalogs,
                  callback: function($$v) {
                    _vm.$set(_vm.participant, "catalogs", $$v)
                  },
                  expression: "participant.catalogs"
                }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "vx-col 1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }